import React from "react"
import Navbar from "../components/navbar"
import AuthOverlay from './authOverlay'

const Layout = ({ children }) => {
  return (
    <>
    <AuthOverlay />
    <div>
        <Navbar></Navbar>
        <div className={'container mx-auto min-h-full text-gray-500'}>
          {children}
        </div>
      </div>
    </>
  )
}

export default Layout