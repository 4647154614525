import React from "react"
import Description from "../components/partDescription"
import Gallery from "../components/partGallery"
import ApplicationsData from "../components/applicationsData"

const PartContent = ({ props }) => {
  const images = props.part.field_images;
  const imageFiles = images.map(function(val, index) {
    return {
      'original' : process.env.GATSBY_DRUPAL_URL+'sites/default/files/styles/' + process.env.GATSBY_IMG_ORIG + '/public/parts/'.concat(val), 
      'thumbnail': process.env.GATSBY_DRUPAL_URL+'sites/default/files/styles/' + process.env.GATSBY_IMG_THUMB + '/public/parts/'.concat(val)
    }
  });
  const applications = props.part.field_k_types;
  return (
      <div>
        <h1 className={'text-4xl pt-8 pb-4'}>{props.ref}</h1>
        <div className={'flex flex-row flex-wrap mb-8'}>
          <div className={'w-full px-2 md:m-0 lg:w-1/2 overflow-hidden'}>
            <Gallery  data={imageFiles}></Gallery>
          </div>
          <div className={'w-full md:m-0 lg:w-1/2'}>
            <Description data={props}></Description>
          </div>
        </div>
        { process.env.APPLICATION_DATA === 'TRUE' &&  <ApplicationsData data = {applications}></ApplicationsData>}
      </div>
  )
};

export default PartContent