import React from "react"
import ApplicationsTable from "../components/applicationsTable"

const ApplicationsData = ( data ) => {
  return (
    <>
    <h2 className="m-2 text-2xl">Application Data</h2>
        <div className={'m-2'}>
          <ApplicationsTable data={data} ></ApplicationsTable>
        </div>
    </>
  )
}

export default ApplicationsData