import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

// Generate image array. TODO if data is empty then set default.
const placeholders = [
  {
    original: 'https://placehold.co/1000x600?text=Waiting+for+image',
    thumbnail: 'https://placehold.co/250x150?text=Waiting+for+image',
  },
];

// Gallery component.
const Gallery = (data) => {
  let images = data.data
  if (images.length === 0) {
    images = placeholders
  }
  return (
      <ImageGallery items={images} />
  );
};

export default Gallery;