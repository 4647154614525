import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

const columns = [
    {
        name: 'Make',
        selector: row => row.field_make,
    },
    {
        name: 'Model',
        selector: row => row.field_model,
    },
    {
        name: 'Model Series',
        selector: row => row.field_model_series,
    },
    {
        name: 'Engine',
        selector: row => `${row.field_engine_size} ${row.field_bhp}bhp ${row.field_cc}cc ${row.field_cylinders}Cylinders ${row.field_kw}KW`,
    },
    {
        name: 'Engine Code',
        selector: row => row.field_engine_code,
    },
    {
        name: 'search',
        selector: row => row.search,
        omit: true,
    },
];


const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled.button`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: rgb(59, 130, 246);
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<TextField
			id="search"
			type="text"
			placeholder="Filter By Name"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
		<ClearButton type="button" onClick={onClear}>
			X
		</ClearButton>
	</>
);

const ApplicationsTable = ( data ) => {
    
    data.data.data.forEach(item => {
        item["search"] = item.field_make.name+item.field_model+item.field_model_series+item.field_engine_code;
    });
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = data.data.data.filter(
        item => item.search && item.search.toLowerCase().includes(filterText.toLowerCase()),
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DataTable
            columns={columns}
            data={filteredItems}
            fixedHeader={true}
            pagination
            subHeader
			subHeaderComponent={subHeaderComponentMemo}
        />
    );
};

export default ApplicationsTable;