import React from "react"
import Layout from "../components/layout"
import PartContent from "../components/partContent"
import PrivateContent from '../components/privateContent'

const PartPage = ({ pageContext}) => {
  return (
    <Layout>
      <PrivateContent
        as={PartContent}
        callbackPath={"/part/".concat(pageContext.PageId)}
        props={pageContext}
      />
    </Layout>
  )
}

export default PartPage;